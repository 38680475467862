import TablePagination from "@mui/material/TablePagination";
import styles from "./cards.module.scss";
import SearchBar from "src/components/UI/searchBar/SearchBar";
import Button from "@mui/material/Button";
import Table from "rc-table";
import columns from "./cardsConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { Card, getAllCardsAction, setUsersAction } from "./cardSlice";
// import { CardAccessType } from "src/types/commonTypes";
import TransactionWelCard from "src/components/transactionWelCard/TransactionWelCard";
import { CARD_LIMIT, CARD_PAGE } from "src/components/constant/queryConstants";
import { useLocation, useSearchParams } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import OrderCardModal from "src/components/orderCardModal/OrderCardModal";
import Modalui from "src/components/UI/modal/Modalui";
// import { CardAccessType, CardStatus } from "src/types/commonTypes";
import { CAN_ADD_CARD, CAN_UPGRADE_CARD } from "src/components/constant/constant";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
import CardFilterModal from "src/components/cardFilter/CardFilter";
import FilterTag from "src/components/UI/filterTag/FilterTag";
import { FilterTagType, generateFilterTags } from "src/components/UI/filterTag/filterTagUtils";
import { setCardFilters, setColumnFilter } from "../filtersSlice";
import { User } from "src/types/commonTypes";
import { Wallet } from "../wallets/walletSlice";
import { checkPermission, upgradePlan } from "src/utils/utils";
import CardEdit from "src/components/cardEdit/CardEdit";
import TableColumnFilter from "src/components/UI/tableColumnFIlter/TableColumnFilter";

function Cards() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { cards, loading } = useAppSelector((state) => state.cards);
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState("");
  const [openOrderCard, setOpenOrderCard] = useState(false);
  const [openFilterCard, setOpenFilterCard] = useState(false);
  const { card: cardFilters, column: columnFilter } = useAppSelector((state) => state.filters);

  const page = searchParams.get(CARD_PAGE) ?? "0";
  const rowsPerPage = searchParams.get(CARD_LIMIT) ?? "10";

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    searchParams.set(CARD_PAGE, String(newPage));
    setSearchParams(searchParams);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    searchParams.set(CARD_LIMIT, event.target.value as string);
    searchParams.set(CARD_PAGE, "0");
    setSearchParams(searchParams);
  };

  const fetchCardsData = useCallback(() => {
    const pageNo = parseInt(page);
    const limit = parseInt(rowsPerPage);

    const card_filter: any = {};

    if (cardFilters.status) {
      card_filter.card_status = cardFilters.status;
    }
    if (cardFilters.type) {
      card_filter.access_type = cardFilters.type;
    }
    if (cardFilters.wallet && cardFilters.wallet.length > 0) {
      card_filter.wallet_ids = cardFilters.wallet.map((wallet) => wallet.id);
    }
    if (cardFilters.user && cardFilters.user.length > 0) {
      card_filter.user_ids = cardFilters.user.map((user) => user.id);
    }
    if (searchText) {
      card_filter.ppan = "*" + searchText + "*";
    }

    dispatch(
      getAllCardsAction({
        card_filter,
        size: limit,
        from: pageNo * limit
      })
    );
  }, [page, rowsPerPage, searchText, cardFilters, dispatch]);

  useEffect(() => {
    fetchCardsData();
  }, [fetchCardsData, location.pathname, cardFilters, searchText]);

  const handleUpdate = () => {
    fetchCardsData();
  };

  function handleSearchText(value: string) {
    setSearchText(value);
  }

  function handleOrderCardModal() {
    setOpenOrderCard(!openOrderCard);
    dispatch(setUsersAction([]));
  }

  const removeTransactionFilter = (filterName: string) => {
    let newFilters;

    if (filterName === "users") {
      newFilters = { ...cardFilters, user: null };
    } else if (filterName === "wallets") {
      newFilters = { ...cardFilters, wallet: null };
    } else {
      newFilters = { ...cardFilters, [filterName]: null };
    }

    dispatch(setCardFilters(newFilters));
  };

  const removeUserWallet = (userId: string, tagKey: string) => {
    if (tagKey === "users") {
      const newUsers = cardFilters?.user?.filter((user: User) => user.id !== userId);

      dispatch(setCardFilters({ ...cardFilters, user: newUsers }));
    }
    if (tagKey === "wallets") {
      const newWallets = cardFilters?.wallet?.filter((wallet: Wallet) => wallet.id !== userId);

      dispatch(setCardFilters({ ...cardFilters, wallet: newWallets }));
    }
  };

  const operation = {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (text: any, card: Card) => <CardEdit onUpdate={handleUpdate} card={card} />
  };

  const filteredColumns = useMemo(() => {
    return columns.filter((col) => columnFilter?.card?.includes(col.key));
  }, [columnFilter?.card]);

  const handleColumnSelection = (selectedColumns: string[]) => {
    dispatch(
      setColumnFilter({
        card: selectedColumns
      })
    );
  };

  const updatedColumns = [...filteredColumns, operation];

  return (
    <>
      <Modalui open={openFilterCard} handleClose={() => setOpenFilterCard(false)} modaluiOver={styles.filterModalOver}>
        <CardFilterModal handleClose={() => setOpenFilterCard(false)} />
      </Modalui>
      <div className={styles.cardsWrapper} aria-label="Cards List">
        <div className={styles.WelCard}>
          <TransactionWelCard heading={"View Your Cards"} subHeading={"View details of all the cards"} />
        </div>
        <div className={styles.cardList}>
          <div className={styles.cardListHead}>
            <div className={styles.cardListHeadIn}>
              <div className={styles.cardListHeadD}>
                <h3>Cards list</h3>
                <p>Manage all the cards</p>
              </div>
              <div className={styles.cardListHeadBtn}>
                <Button
                  variant="contained"
                  className={styles.clearFilter}
                  onClick={() => setOpenFilterCard(true)}
                  aria-label="Filter Cards"
                >
                  Filters Cards
                </Button>
                {checkPermission(CAN_ADD_CARD) && (
                  <div onClick={handleOrderCardModal}>
                    <Button variant="contained" className={styles.orderCardBtn} aria-label="Order Card">
                      <div>
                        <ShoppingCartIcon />
                      </div>
                      <div>Order Card</div>
                    </Button>
                  </div>
                )}
                {checkPermission(CAN_UPGRADE_CARD) && (
                  <div
                    onClick={() => {
                      upgradePlan({
                        title: "Oops! Only the professional plan can create order cards",
                        subTitle: "Click Upgrade Plan and a member of our team will confirm your upgrade."
                      });
                    }}
                  >
                    <Button variant="contained" className={styles.orderCardBtn} aria-label="Order Card">
                      <div>
                        <ShoppingCartIcon />
                      </div>
                      <div>Order Card</div>
                    </Button>
                  </div>
                )}
                <Modalui
                  open={openOrderCard}
                  handleClose={handleOrderCardModal}
                  modaluiOver={styles.modaluiOver}
                  aria-modal="true"
                >
                  <OrderCardModal onSuccess={fetchCardsData} handleClose={handleOrderCardModal} />
                </Modalui>
              </div>
            </div>
            <div className={styles.cardListFilter}>
              <div className={styles.filterTagsContainer}>
                {generateFilterTags(cardFilters).map((filterTag: FilterTagType) => (
                  <FilterTag
                    key={filterTag.key}
                    tagKey={filterTag.key}
                    label={filterTag.label}
                    onRemove={() => removeTransactionFilter(filterTag.key)}
                    users={cardFilters?.user}
                    wallets={cardFilters?.wallet}
                    removeUserWallet={(userId) => removeUserWallet(userId, filterTag.key)}
                  />
                ))}
              </div>
              <div className={styles.cardListSearch}>
                <SearchBar onSearch={handleSearchText} />
              </div>
            </div>
            <TableColumnFilter
              columns={columns}
              selectedColumn={columnFilter?.card}
              mandatoryColumns={["id"]}
              onApply={handleColumnSelection}
            />
          </div>
          <div className={styles.cardListTable} aria-live="polite">
            <Table
              columns={updatedColumns}
              data={loading ? [] : cards.list}
              className={styles.tableIn}
              rowKey="id"
              components={
                loading
                  ? {
                      body: {
                        cell: CircularLoader
                      }
                    }
                  : undefined
              }
            />
          </div>
          <div className={styles.paginationWrapper}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={cards.total ?? 0}
              page={parseInt(page)}
              onPageChange={handleChangePage}
              rowsPerPage={parseInt(rowsPerPage)}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
