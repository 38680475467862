import { createSlice } from "@reduxjs/toolkit";
import { StoreDispatch } from "../store";
import { immediateToast } from "izitoast-react";
import {
  CreateWalletInputType,
  InternalSearchWallet,
  SearchWalletRequest,
  WalletDownloadRequest,
  changeWalletPreference,
  createWallet,
  downloadInternalWallet
} from "src/network/graphql/walletService";
import { User } from "src/types/commonTypes";
import { SearchUserRequest, searchInternalUser } from "src/network/graphql/userService";
import { Wallet } from "../wallets/walletSlice";

const initialWallets: Wallet[] = [];

type ConnectWallet = {
  id: number;
  participant: { fullName: string; role: string };
  description: string;
};
const initialConnectWallets: ConnectWallet[] = [];

type orgList = {
  id: number;
  name: string;
  abn: string;
};

const initialOrgList: orgList[] = [];

type walletTransaction = {
  id: number;
  current: number;
  previous: number;
  positive: boolean;
  diff: number;
};

const initialwalletTransaction: walletTransaction[] = [];

const initialUsers: User[] = [];

const internalWalletSlice = createSlice({
  name: "wallet",
  initialState: {
    walletLoading: false,
    walletConnectLoading: false,
    walletTransLoading: false,
    userLoading: false,
    unlinkWalletLoading: false,
    orgListLoading: false,
    connectWalletLoading: false,
    downloadWalletLoading: false,
    error: null,
    walletList: {
      list: initialWallets,
      total: 0
    },
    walletConnectList: {
      list: initialConnectWallets,
      total: 0
    },
    walletTransaction: initialwalletTransaction,
    users: {
      list: initialUsers,
      total: 0
    },
    orgLists: {
      list: initialOrgList,
      total: 0
    }
  },
  reducers: {
    fetchWalletsStart: (state) => {
      state.walletLoading = true;
    },
    fetchWalletsSuccess: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = action.payload?.list ?? [];
      state.walletList.total = action.payload?.total ?? 0;
    },
    fetchWalletsFail: (state, action) => {
      state.walletLoading = false;
      state.walletList.list = [];
      state.walletList.total = 0;
      state.error = action.payload;
    },
    fetchWalletConnectStart: (state) => {
      state.walletConnectLoading = true;
    },
    fetchWalletConnectSuccess: (state, action) => {
      state.walletConnectLoading = false;
      state.walletConnectList.list = action.payload?.list ?? [];
      state.walletConnectList.total = action.payload?.total ?? 0;
    },
    fetchWalletConnectFail: (state, action) => {
      state.walletConnectLoading = false;
      state.walletConnectList.list = [];
      state.walletConnectList.total = 0;
      state.error = action.payload;
    },
    setWalletConnectAction: (state, action) => {
      state.walletConnectList.list = action?.payload;
    },
    fetchWalletTransactionsStart: (state) => {
      state.walletTransLoading = true;
    },
    fetchWalletTransactionsSuccess: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = action?.payload ?? [];
    },
    fetchWalletTransactionsFail: (state, action) => {
      state.walletTransLoading = false;
      state.walletTransaction = [];
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    resetUserList: (state, action) => {
      state.users.list = action?.payload ?? [];
    },
    addWalletStart: (state) => {
      state.walletLoading = true;
    },
    addWalletSuccess: (state) => {
      state.walletLoading = false;
      state.error = null;
    },
    addWalletFail: (state, action) => {
      state.walletLoading = false;
      state.error = action.payload;
    },
    unlinkWalletStart: (state) => {
      state.unlinkWalletLoading = true;
    },
    unlinkWalletSuccess: (state) => {
      state.unlinkWalletLoading = false;
      state.error = null;
    },
    unlinkWalletFail: (state, action) => {
      state.unlinkWalletLoading = false;
      state.error = action.payload;
    },
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    setOrgListAction: (state, action) => {
      state.orgLists.list = action?.payload;
    },
    connectWalletStart: (state) => {
      state.connectWalletLoading = true;
    },
    connectWalletSuccess: (state) => {
      state.connectWalletLoading = false;
      state.error = null;
    },
    connectWalletFail: (state, action) => {
      state.connectWalletLoading = false;
      state.error = action.payload;
    },
    downloadWalletStart: (state) => {
      state.downloadWalletLoading = true;
    },
    downloadWalletSuccess: (state) => {
      state.downloadWalletLoading = false;
    },
    downloadWalletFail: (state, action) => {
      state.downloadWalletLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  fetchWalletsStart,
  fetchWalletsSuccess,
  fetchWalletsFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  addWalletStart,
  addWalletSuccess,
  addWalletFail,
  downloadWalletStart,
  downloadWalletSuccess,
  downloadWalletFail
} = internalWalletSlice.actions;

export const { setOrgListAction, setWalletConnectAction, resetUserList } = internalWalletSlice.actions;

export const fetchWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchWalletsStart());
    try {
      const response = await InternalSearchWallet(data);

      const structuredResponse = response.data.internal_search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        organisationId: wallet.organisation_id,
        name: wallet.name,
        walletType: wallet.wallet_type,
        participant: {
          role: "PARTICIPANT",
          fullName: `${wallet.user?.attributes?.first_name ?? "-"} ${wallet.user?.attributes?.last_name ?? "-"}`,
          email: wallet?.user?.email
        }
      }));

      dispatch(
        fetchWalletsSuccess({
          list: structuredResponse,
          total: response.data.internal_search_wallet.total_size
        })
      );
    } catch (error) {
      dispatch(fetchWalletsFail(error));
    }
  };
};

export const fetchWalletConnectListAction = (data: any) => {
  return data;
};

export const fetchWalletTransactionAction = (data: any) => {
  return data;
};

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchInternalUser(data);

      const structuredResponse = response.data.internal_search_user.user_listing.map((user: any) => ({
        id: user.user_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        role: "not available",
        status: user.is_active
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.internal_search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const addWalletAction = (data: CreateWalletInputType, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(addWalletStart());
    try {
      const response = await createWallet(data);

      dispatch(addWalletSuccess());
      immediateToast("success", {
        message: "Wallet Successfully Added.",
        timeout: 3000,
        position: "topCenter"
      });
      if (cb) cb();

      await changeWalletPreference({
        spendFrequency: "WEEKLY",
        spendLimit: 1000,
        cashWithdrawalAllowed: false,
        maxAllowedLimit: 1000,
        walletId: response.data.create_wallet.wallet_id
      });
    } catch (error) {
      dispatch(addWalletFail(error));
    }
  };
};

export const downloadWalletAction = (data: WalletDownloadRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(downloadWalletStart());
    try {
      const response = await downloadInternalWallet(data);
      const downloadUrl = response.data.internal_download_wallets.download_url;

      if (downloadUrl) {
        window.open(downloadUrl, "_blank");
      }

      if (!response.data.internal_download_wallets.count || response.data.internal_download_wallets.count === 0) {
        immediateToast("error", {
          message: "No Wallet Found",
          timeout: 3000,
          position: "topCenter"
        });
      }
      dispatch(downloadWalletSuccess());
    } catch (err) {
      dispatch(downloadWalletFail(err));
    }
  };
};

export const unlinkWalletAction = (data: any) => {
  return data;
};

export const fetchOrgListAction = (data: any) => {
  return data;
};

export const connectWalletAction = (organisationId: number, data: any, sucessfullMessage: string) => {
  return Promise.resolve(organisationId + data.action + sucessfullMessage);
};

export default internalWalletSlice.reducer;
