import { Status, WalletType } from "src/types/commonTypes";
import styles from "../walletListCard.module.scss";
import { Link } from "react-router-dom";
import { Avatar } from "@mui/material";
import { bgColorbyName, typeColors } from "src/variable/randomColor";
import HighLighter from "src/components/UI/highlighter/HighLighter";
import { getDecimalNumber } from "src/utils/utils";
import { Wallet } from "src/page/wallets/walletSlice";
import { walletTypeMapping } from "src/components/constant/constant";
// import { walletTypeMapping } from "src/components/constant/constant";

const columns = (searchText: string = "") => [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
    render: (id: string) => <div className={styles.id}>{id}</div>
  },
  {
    title: "Display Id",
    dataIndex: "displayId",
    key: "displayId",
    render: (displayId: string) => <div className={styles.id}>{displayId}</div>
  },
  {
    title: "Wallet Name",
    dataIndex: "name",
    key: "name",
    render: (name: string, record: Wallet) => (
      <div className={styles.tableMemberDetail}>
        <div className={styles.tableWalletName}>
          <Link
            to={`/wallets/${record.id}`}
            className={styles.walletLink}
            aria-label={`View details for wallet ${name}`}
            title={`View details for wallet ${name}`}
          >
            <HighLighter searchText={searchText} text={`${name} `} />
          </Link>
        </div>
      </div>
    )
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (description: string) => (
      <div className={styles.tableMemberDetail}>
        <div className={styles.tableWalletName}>{description}</div>
      </div>
    )
  },
  {
    title: "Participant",
    dataIndex: "participant",
    key: "participant",
    render: (participant: { fullName: string; email: string }) => (
      <div className={styles.tableMember}>
        <div className={styles.tableMemberImg}>
          <Avatar id="av" aria-label="Participant" style={{ backgroundColor: bgColorbyName(participant?.fullName) }}>
            {participant?.fullName?.charAt(0).toLocaleUpperCase()}
          </Avatar>
        </div>
        <div className={styles.tableMemberDetail}>
          <div className={styles.tableMemberName}>{participant?.fullName}</div>
          <div className={styles.tableMemberEmail}>{participant.email}</div>
        </div>
      </div>
    )
  },
  {
    title: "Balance",
    dataIndex: "balance",
    key: "balance",
    render: (balance: number) => (
      <div className={styles.tableBalance}>
        <div
          className={styles.tableBalanceWallet}
          style={{
            color: `${balance === 0 ? "" : balance > 0 ? "green" : "red"}`
          }}
        >{`${balance >= 0 ? "$" : "-$"}${getDecimalNumber(balance)}`}</div>
      </div>
    )
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: Status) => (
      <div className={styles.tableCardStatus}>
        <div className={`${status === "ACTIVE" ? styles.green : styles.grey}`}>
          {status === "ACTIVE" ? "Active" : "Inactive"}
        </div>
      </div>
    )
  },
  {
    title: "Type",
    dataIndex: "walletType",
    key: "walletType",
    render: (walletType: WalletType) => (
      <div className={styles.type}>
        <div
          className={styles.typeColor}
          style={{
            backgroundColor: `${typeColors[walletType]}0d`,
            borderColor: `${typeColors[walletType]}73`,
            color: `${typeColors[walletType]}FF`
          }}
        >
          {walletTypeMapping[walletType]}
        </div>
      </div>
    )
  },
  {
    title: "",
    dataIndex: "",
    key: "operations",
    render: (data: Wallet) => (
      <div className={styles.edit}>
        <Link to={`/wallets/${data.id}`} aria-label={`Edit wallet ${data.name}`} title={`Edit wallet ${data.name}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path
              d="M8.88541 3.05214L10.9478 5.11453M9.76042 2.17714C10.3299 1.60762 11.2533 1.60762 11.8228 2.17714C12.3924 2.74665 12.3924 3.67002 11.8228 4.23953L3.79167 12.2707H1.75V10.1876L9.76042 2.17714Z"
              stroke="#64748B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
      </div>
    )
  }
];

export default columns;
