import { createSlice } from "@reduxjs/toolkit";
import { CardAccessType, CardEventAction, User } from "src/types/commonTypes";
import { StoreDispatch } from "../store";
import {
  CardSearchRequest,
  CreateCardRequest,
  UpdateCardRequest,
  cardEvent,
  changeCardPin,
  createCard,
  internalCardSearch,
  updateCard
} from "src/network/graphql/cardService";
import { SearchUserRequest, getUserById, searchInternalUser } from "src/network/graphql/userService";
import { immediateToast } from "izitoast-react";
import { initialUserDetail } from "../userDetail/userDetailSlice";
import { SearchWalletRequest, searchWallet } from "src/network/graphql/walletService";
import { Wallet } from "../wallets/walletSlice";

export type Card = {
  id: string;
  cardNumber: string;
  orderId: string;
  clientId: string;
  cardStatus: string;
  cardLimit: number;
  accessType: CardAccessType;
  user: User;
  cardHolderName: string;
  bsb: string;
  organisationId: number;
};

const initialCards: Card[] = [];

const initialUsers: User[] = [];

type orgList = {
  id: number;
  name: string;
  abn: string;
  address: string;
  phone: string;
  countryCode: string;
  website: string;
  facebook: string;
  linkedIn: string;
  instagram: string;
  referenceName: string;
  referenceRole: string;
  referenceCountryCode: string;
  referencePhone: string;
  referenceEmail: string;
  createdBy: string;
  createdOn: string;
  updatedBy: string;
  updatedOn: string;
};

const initialOrgList: orgList[] = [];
const initialWalletOptions: Wallet[] = [];

const internalCardSlice = createSlice({
  initialState: {
    loading: false,
    updateCardLoading: false,
    updateStatusLoading: false,
    userLoading: false,
    userDataLoading: false,
    orgListLoading: false,
    orderCardLoading: false,
    cardPinLoading: false,
    error: null,
    cards: {
      list: initialCards,
      total: 0
    },
    users: {
      list: initialUsers,
      total: 0
    },
    userData: initialUserDetail,
    orgLists: {
      list: initialOrgList,
      total: 0
    },
    walletOptions: {
      list: initialWalletOptions
    }
  },
  name: "cards",
  reducers: {
    getAllCardsStart: (state) => {
      state.loading = true;
      state.error = null;
    },
    getAllCardsFail: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getAllCardsSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.cards = action.payload;
    },
    updateCardStart: (state) => {
      state.updateCardLoading = true;
      state.error = null;
    },
    updateCardSuccess: (state) => {
      state.updateCardLoading = false;
    },
    updateCardFail: (state, action) => {
      state.updateCardLoading = false;
      state.error = action.payload;
    },
    updateCardStatusStart: (state) => {
      state.updateStatusLoading = true;
      state.error = null;
    },
    updateCardStatusSuccess: (state) => {
      state.updateStatusLoading = false;
    },
    updateCardStatusFail: (state, action) => {
      state.updateStatusLoading = false;
      state.error = action.payload;
    },
    fetchUsersStart: (state) => {
      state.userLoading = true;
    },
    fetchUsersSuccess: (state, action) => {
      state.userLoading = false;
      state.users.list = action.payload?.list ?? [];
      state.users.total = action.payload?.total ?? 0;
    },
    fetchUsersFail: (state, action) => {
      state.userLoading = false;
      state.users.list = [];
      state.users.total = 0;
      state.error = action.payload;
    },
    setUsersAction: (state, action) => {
      state.users.list = action.payload;
    },
    fetchUserDataStart: (state) => {
      state.userDataLoading = true;
    },
    fetchUserDataSuccess: (state, action) => {
      state.userDataLoading = false;
      state.userData = action.payload ?? initialUserDetail;
    },
    fetchUserDataFail: (state, action) => {
      state.userDataLoading = false;
      state.userData = initialUserDetail;
      state.error = action.payload;
    },
    fetchOrgListStart: (state) => {
      state.orgListLoading = true;
    },
    fetchOrgListSuccess: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = action.payload?.list ?? [];
      state.orgLists.total = action.payload?.total ?? 0;
    },
    fetchOrgListFail: (state, action) => {
      state.orgListLoading = false;
      state.orgLists.list = [];
      state.orgLists.total = 0;
      state.error = action.payload;
    },
    setOrganisationAction: (state, action) => {
      state.orgLists.list = action.payload;
    },
    setWalletOptionsAction: (state, action) => {
      state.walletOptions.list = action.payload;
    },
    orderCardStart: (state) => {
      state.orderCardLoading = true;
    },
    orderCardSuccess: (state) => {
      state.orderCardLoading = false;
    },
    orderCardFail: (state, action) => {
      state.orderCardLoading = false;
      state.error = action.payload;
    },
    changeCardPinStart: (state) => {
      state.cardPinLoading = true;
    },
    changeCardPinSuccess: (state) => {
      state.cardPinLoading = false;
    },
    changeCardPinFail: (state, action) => {
      state.cardPinLoading = false;
      state.error = action.payload;
    }
  }
});

const {
  getAllCardsStart,
  getAllCardsSuccess,
  getAllCardsFail,
  updateCardStart,
  updateCardSuccess,
  updateCardFail,
  updateCardStatusStart,
  updateCardStatusSuccess,
  updateCardStatusFail,
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  fetchUserDataStart,
  fetchUserDataSuccess,
  fetchUserDataFail,
  orderCardStart,
  orderCardSuccess,
  orderCardFail,
  changeCardPinSuccess,
  changeCardPinStart,
  changeCardPinFail
} = internalCardSlice.actions;

export const { setUsersAction, setOrganisationAction, setWalletOptionsAction } = internalCardSlice.actions;

export const getAllCardsAction = (data: CardSearchRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(getAllCardsStart());
    try {
      const response = await internalCardSearch(data);
      const structuredResponse = response.data.internal_search_card.card_listing?.map(
        (card: any) =>
          ({
            id: card.card_id,
            bsb: card.bsb,
            cardStatus: card.card_status,
            accessType: card.card_type,
            cardNumber: card.ppan,
            orderId: card.order_id,
            clientId: card.clientId,
            cardLimit: card.card_limit,
            cardHolderName: card.holder_name,
            organisationId: card?.organisation_id,
            user: {
              id: card?.user_id,
              firstName: card?.user?.attributes?.first_name ?? "---",
              lastName: card?.user?.attributes?.last_name ?? " ",
              email: card?.user?.email ?? " "
            }
          }) as Card
      );

      dispatch(
        getAllCardsSuccess({
          list: structuredResponse,
          total: response.data.internal_search_card.total_size
        })
      );
    } catch (error) {
      dispatch(getAllCardsFail(error));
    }
  };
};
export const updateCardAction = (data: UpdateCardRequest, cb: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateCardStart());
    try {
      await updateCard(data);

      dispatch(updateCardSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(updateCardFail(error));
    }
  };
};

export const updateCardStatusAction = (id: string, action: CardEventAction, cb: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(updateCardStatusStart());
    try {
      await cardEvent(id, action);

      dispatch(updateCardStatusSuccess());
      if (cb) cb();
    } catch (error) {
      dispatch(updateCardStatusFail(error));
    }
  };
};

export const fetchUserListAction = (data: SearchUserRequest) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUsersStart());
    try {
      const response = await searchInternalUser(data);

      const structuredResponse = response.data.internal_search_user.user_listing.map((user: any) => ({
        id: user.user_id,
        firstName: user.attributes.first_name,
        lastName: user.attributes.last_name,
        email: user.email,
        role: user.current_organisation_role,
        status: user.is_active,
        userId: user.user_id
      }));

      dispatch(
        fetchUsersSuccess({
          list: structuredResponse,
          total: response.data.internal_search_user.total_size
        })
      );
    } catch (error) {
      dispatch(fetchUsersFail(error));
    }
  };
};

export const fetchUserDataAction = (data: string) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(fetchUserDataStart());
    try {
      const response = await getUserById(data);
      const user = response.data.get_user;

      dispatch(
        fetchUserDataSuccess({
          user: {
            id: user.user_id,
            initial: user.attributes.initial,
            firstName: user.attributes.first_name,
            lastName: user.attributes.last_name,
            email: user.email,
            userName: user.username,
            gender: user.attributes.gender,
            dob: user.attributes.date_of_birth,
            role: user.roles,
            phoneNumber: user.phone,
            line1: user.attributes.address.line_1,
            line2: user.attributes.address.line_2,
            city: user.attributes.address.city,
            state: user.attributes.address.state,
            zipcode: user.attributes.address.post_code,
            country: user.attributes.address.country,
            active: user.is_active,
            kycStatus: user.security_status,
            guardianId: user.guardian_id,
            profileImage: user.attributes.profile_image,
            displayId: user.display_id
          }
        })
      );
    } catch (error) {
      dispatch(fetchUserDataFail(error));
    }
  };
};

export const fetchOrgListAction = (data: any) => {
  return data;
};
export const fetchWalletListAction = (data: SearchWalletRequest) => {
  return async (dispatch: StoreDispatch) => {
    try {
      const response = await searchWallet(data);

      const structuredResponse = response.data.search_wallet.items.map((wallet: any) => ({
        id: wallet.wallet_id,
        displayId: wallet.display_id,
        description: wallet.description,
        balance: wallet.balance,
        status: wallet.wallet_status,
        name: wallet.name,
        participant: {
          role: "PARTICIPANT",
          fullName: `${wallet.user?.attributes?.first_name ?? "-"} ${wallet.user?.attributes?.last_name ?? "-"}`,
          email: wallet?.user?.email
        }
      }));

      dispatch(setWalletOptionsAction(structuredResponse));
    } catch (error) {
      console.log(error);
    }
  };
};

export const orderCardAction = (data: CreateCardRequest, cb?: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(orderCardStart());
    try {
      const response = (await createCard(data)) as unknown as { errors?: Error[] };

      if (response.errors) {
        immediateToast("error", {
          message: response.errors[0].message,
          timeout: 3000,
          position: "topCenter"
        });
      } else {
        immediateToast("success", {
          message: "Card created successfully",
          timeout: 3000,
          position: "topCenter"
        });
        dispatch(orderCardSuccess());
        if (cb) cb();
      }
    } catch (error) {
      dispatch(orderCardFail(error));
    }
  };
};

export const changeCardPinAction = (cardId: string, CardNewPin: string, cb: () => void) => {
  return async (dispatch: StoreDispatch) => {
    dispatch(changeCardPinStart());
    try {
      const response = await changeCardPin(cardId, CardNewPin);

      immediateToast("success", {
        message: "Pin change successfully",
        timeout: 3000,
        position: "topCenter"
      });
      dispatch(changeCardPinSuccess());
      if (cb) cb();
      return response;
    } catch (err) {
      dispatch(changeCardPinFail(err));
    }
  };
};

export default internalCardSlice.reducer;
