import { useState, useEffect, Suspense, lazy, useCallback } from "react";
import styles from "./userDetail.module.scss";
import UserInfocard from "src/components/userInfoCard/UserInfocard";
import ConnectCard from "src/components/connectCard/ConnectCard";
import RecentTrans from "src/components/recentTrans/RecentTrans";
import PersonWelCard from "src/components/personWelCard/PersonWelCard";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/page/store";
import {
  fetchUserDetailAction,
  fetchWalletConnectAction,
  fetchWalletTransactionAction,
  fetchTransListAction,
  addWalletSupporterAction,
  deleteWalletSupporterAction,
  getAllCardsAction
} from "src/page/userDetail/userDetailSlice";
import LoadingModal from "src/components/UI/loadingModal/LoadingModal";
import AddIcon from "@mui/icons-material/Add";
import { checkPermission, getDecimalNumber } from "src/utils/utils";
import { CAN_CONNECT_WALLET_TO_USER, CAN_DEPOSIT_TO_CASH_WALLET } from "src/components/constant/constant";
import Modalui from "src/components/UI/modal/Modalui";
import WalletUserModal from "src/components/connectCard/supporterModal/WalletUserModal";
import Table from "rc-table";
import columns from "../cards/cardsConfig";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Button } from "@mui/material";
import OrderCardModal from "src/components/orderCardModal/OrderCardModal";
import { Wallet } from "../wallets/walletSlice";
import CircularLoader from "src/components/UI/circularLoader/CircularLoader";
const DepositModal = lazy(() => import("src/components/depositModal/DepositModal"));

export default function UserPage() {
  const [walletOpen, setWalletOpen] = useState<boolean>(false);
  // const [searchText, setSearchText] = useState("");
  const [receiptPresent, setReceiptPresent] = useState<null | boolean>(null);
  const [isReviewed, setIsReviewed] = useState<null | boolean>(null);
  const [openOrderCard, setOpenOrderCard] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState<boolean>(false);
  const [cashWallet, setCashWallet] = useState<Wallet | null>(null);
  const { userId } = useParams();

  const { userDetail, WalletConnect, transList, userDetailLoading, cards, loading } = useAppSelector(
    (state) => state.userDetail
  );
  const { cashConfigFlag } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetailAction(userId)).then(() => {
        fetchWalletTransactionAction({ participantIds: [Number(userId)] });
        dispatch(
          getAllCardsAction({
            card_filter: {
              user_ids: [userId]
            },
            size: 100,
            from: 0
          })
        );
      });
    }
  }, [dispatch, userId]);

  const fetchCardsData = () => {
    if (userId) {
      dispatch(
        getAllCardsAction({
          card_filter: {
            user_ids: [userId]
          },
          size: 100,
          from: 0
        })
      );
    }
  };

  const fetchWalletsConnect = useCallback(() => {
    if (userId) {
      dispatch(fetchWalletConnectAction({ userId }));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    fetchWalletsConnect();
  }, []);

  useEffect(() => {
    if (WalletConnect && WalletConnect.wallets) {
      const wallet = WalletConnect.wallets.find((w: Wallet) => w.walletType === "CASH");
      if (wallet) {
        setCashWallet(wallet);
      }
    }
  }, [WalletConnect]);

  const fetchRecentTransaction = useCallback(() => {
    if (userDetail) {
      const requestData: any = {
        size: 5,
        from: 0,
        filter: {
          userIds: [userId]
        }
      };

      dispatch(fetchTransListAction(requestData));
    }
  }, [receiptPresent, isReviewed, dispatch, userDetail, userId]);

  useEffect(() => {
    fetchRecentTransaction();
  }, []);

  const handleAddWallet = (email: string, walletId: string, close: boolean, applyToAll: boolean) => {
    if (userId) {
      dispatch(
        addWalletSupporterAction(walletId, userId, applyToAll, () => {
          dispatch(fetchUserDetailAction(userId));
          dispatch(fetchWalletConnectAction({ userId }));
          if (close) {
            setWalletOpen(false);
          }
        })
      );
    }
  };

  const handleDeleteWallet = (email?: string, walletId?: string, removeItFromAll?: boolean) => {
    if (userId && walletId) {
      dispatch(deleteWalletSupporterAction(walletId, userId, removeItFromAll || false)).then(() => {
        dispatch(fetchUserDetailAction(userId));
        dispatch(fetchWalletConnectAction({ userId }));
      });
    }
  };

  const walletConnectHeading = "Wallet Connect";

  const deleteWalletInput = {
    buttonInfo: "Remove from user",
    whomToRemove: "user's",
    headInfo: "Do you want to remove this Wallet?"
  };

  function handleOrderCardModal() {
    setOpenOrderCard(!openOrderCard);
  }

  return (
    <>
      <Modalui
        open={openDepositModal}
        handleClose={() => {
          setOpenDepositModal(false);
        }}
      >
        <Suspense fallback={<CircularLoader />}>
          <DepositModal
            particpant={{
              name: userDetail?.user?.initial + " " + userDetail?.user?.firstName + " " + userDetail?.user?.lastName,
              email: userDetail?.user?.email,
              userId: String(userDetail?.user?.id),
              displayId: userDetail?.user?.displayId
            }}
            wallet={{
              walletId: String(cashWallet?.id),
              displayId: String(cashWallet?.displayId),
              type: String(cashWallet?.walletType),
              name: String(cashWallet?.name),
              description: String(cashWallet?.description)
            }}
            handleClose={() => {
              setOpenDepositModal(false);
            }}
            cb={() => {
              fetchWalletsConnect();
              fetchRecentTransaction();
            }}
          />
        </Suspense>
      </Modalui>
      <Modalui
        open={walletOpen}
        handleClose={() => {
          setWalletOpen(false);
        }}
      >
        <WalletUserModal
          handleClose={() => {
            setWalletOpen(false);
          }}
          participantName={
            userDetail?.user?.initial + " " + userDetail?.user?.firstName + " " + userDetail?.user?.lastName
          }
          onAddSupporter={handleAddWallet}
        />
      </Modalui>
      <Modalui open={openOrderCard} handleClose={handleOrderCardModal} modaluiOver={styles.modaluiOver}>
        <OrderCardModal
          onSuccess={fetchCardsData}
          handleClose={handleOrderCardModal}
          userDetail={userDetail}
          hideUserInput={true}
        />
      </Modalui>
      <div className={styles.userPage} aria-label="User Page" aria-live="polite">
        {userDetailLoading ? <LoadingModal /> : null}
        <div className={styles.userPageWelCard}>
          <PersonWelCard
            img={userDetail?.user?.firstName?.charAt(0).toLocaleUpperCase()}
            imgName={userDetail?.user?.firstName}
            heading={userDetail?.user?.initial + " " + userDetail?.user?.firstName + " " + userDetail?.user?.lastName}
            subHeading1={userDetail?.user?.email}
            subHeading2={userDetail?.user?.displayId}
            connectedTo={"Users"}
            // welCardLOver={styles.welCardLOver}
            // status={userDetail.active}
          />
        </div>
        <div className={styles.userPageHead}>
          {userDetail && <UserInfocard userId={userDetail} />}
          <ConnectCard
            walletUsers={WalletConnect.wallets}
            heading={walletConnectHeading}
            connectedTo={"User"}
            walletMemberOver={styles.walletMemberOver}
            onDeleteSupporter={handleDeleteWallet}
            deleteInput={deleteWalletInput}
            modalOf={"UNLINK"}
          >
            {checkPermission(CAN_CONNECT_WALLET_TO_USER) && (
              <AddIcon
                className={styles.editIcon}
                onClick={() => {
                  setWalletOpen(true);
                }}
                aria-label="Add new wallet"
              />
            )}
          </ConnectCard>
          {cashWallet && (
            <div className={styles.cashCard}>
              <div className={styles.cashCardHeader}>
                <h3>{userDetail?.user?.firstName}'s Cash Box</h3>
                {cashWallet && checkPermission(CAN_DEPOSIT_TO_CASH_WALLET) && (
                  <Button
                    variant="contained"
                    className={styles.cashDeposit}
                    onClick={() => {
                      setOpenDepositModal(true);
                    }}
                  >
                    Deposit Cash
                  </Button>
                )}
              </div>
              {cashWallet ? (
                <div className={styles.cashCardMain}>
                  <h2
                    style={{
                      color:
                        cashWallet?.balance === undefined
                          ? ""
                          : cashWallet.balance === 0
                            ? ""
                            : cashWallet.balance > 0
                              ? "green"
                              : "red"
                    }}
                  >
                    {`${cashWallet.balance > 0 ? "$" : "-$"}${getDecimalNumber(cashWallet?.balance ?? 0)}`}
                  </h2>
                  <h3>Cash Balance</h3>
                </div>
              ) : (
                <div className={styles.noData}>
                  <p>No Cash Wallet Found</p>
                </div>
              )}
            </div>
          )}
        </div>
        <div className={styles.userPageMid}>
          <RecentTrans
            transList={transList.trans}
            setReceiptPresent={setReceiptPresent}
            setIsReviewed={setIsReviewed}
            userId={String(userId)}
            role={"PARTICIPANT"}
            recentTransOver={styles.recentTransOver}
            subTitle={"These are the details of the transactions made by this user"}
            YAxisScrollHeight={180}
          />

          <div className={styles.transCards}>
            <h1>Insights</h1>
            <div className={styles.transCardContainer}>
              <h2>{transList.total}</h2>
              <h3>Number of Transactions</h3>
            </div>
            <div className={styles.transCardContainer}>
              <h2>{cards.total}</h2>
              <h3>Number of Cards</h3>
            </div>
          </div>
        </div>
        <div className={styles.userPageBottom}>
          {!cashConfigFlag && (
            <div className={styles.cardListContainer}>
              <div className={styles.cardListTable}>
                <div className={styles.cardListHead}>
                  <div className={styles.cardListHeadD}>
                    <h3>{`${userDetail?.user?.firstName}'s Card`}</h3>
                    <p>List of users Card</p>
                  </div>
                  <Button
                    variant="contained"
                    className={styles.orderCardBtn}
                    aria-label="Order a new card for the user"
                    onClick={handleOrderCardModal}
                  >
                    <div>
                      <ShoppingCartIcon />
                    </div>
                    <div>Order Card</div>
                  </Button>
                </div>
                {cards.list && cards.list?.length > 0 ? (
                  <Table columns={columns} data={loading ? [] : cards.list} className={styles.tableIn} rowKey="id" />
                ) : (
                  <div className={styles.noData}>
                    <p>No Bulk Card Found</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
